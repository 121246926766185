interface IFlowerShopClass {
  isMobile(): boolean;
  showCatalog(): void;
  hideCatalog(): void;
}

interface ISearchFormElements extends HTMLCollection {
  text: HTMLInputElement;
  ismobile: HTMLInputElement;
}

interface ISearchResponse {
  count: number;
  list: any;
}

var fsCommon: IFlowerShopClass;
var headerPopup: any;

(function(document) {

  var search: SearchClass;

  class HeaderPopupClass {

    show(name: string) {
      var
        popupContainer: HTMLElement,
        btnActive: HTMLElement;

      popupContainer = document.querySelector(".js-popup-container-" + name);
      btnActive = document.querySelector(".js-popup-btn.active");

      document.body.classList.add("opacity-layer-active");

      if (document.querySelector(".js-popup-container.slideInDown")) {
        document.querySelector(".js-popup-container.slideInDown").classList.add('slideOutUp');
        document.querySelector(".js-popup-container.slideInDown").classList.remove('slideInDown');
      }

      if (document.querySelector(".js-popup-container.slideInRight")) {
        document.querySelector(".js-popup-container.slideInRight").classList.add('slideOutLeft');
        document.querySelector(".js-popup-container.slideInRight").classList.remove('slideInRight');
      }

      if (btnActive) {
        btnActive.classList.remove("active");
      }

      if (document.querySelector(".js-popup-btn-" + name)) {
        document.querySelector(".js-popup-btn-" + name).classList.add("active");
      }

      if (popupContainer) {
        popupContainer.style.display = "block";

        if ('mobile-menu' === name) {
          popupContainer.classList.add('slideInRight');
          popupContainer.classList.remove('slideOutLeft');
        } else {
          popupContainer.classList.add('slideInDown');
          popupContainer.classList.remove('slideOutUp');
        }
      }
    }

    hide() {
      var
        popupContainerDown: HTMLElement,
        popupContainerRight: HTMLElement,
        btnActive: HTMLElement;

      popupContainerDown = document.querySelector(".js-popup-container.slideInDown");
      popupContainerRight = document.querySelector(".js-popup-container.slideInRight");
      btnActive = document.querySelector(".js-popup-btn.active");

      if (popupContainerDown) {
        popupContainerDown.classList.add("slideOutUp");
        popupContainerDown.classList.remove("slideInDown");
      }

      if (popupContainerRight) {
        popupContainerRight.classList.add("slideOutLeft");
        popupContainerRight.classList.remove("slideInRight");
      }

      if (btnActive) {
        btnActive.classList.remove("active");
      }

      document.body.classList.remove("opacity-layer-active");
    }

    toggle(name: string) {
      var popupContainer = document.querySelector(".js-popup-container-" + name);

      if ('mobile-menu' === name) {
        if (popupContainer && popupContainer.classList.contains("slideInRight")) {
          this.hide();
        } else {
          this.show(name);
        }
      } else {
        if (popupContainer && popupContainer.classList.contains("slideInDown")) {
          this.hide();
        } else {
          this.show(name);
        }
      }
    }
  }

  class SearchClass {

    private form: HTMLFormElement;
    private elements: ISearchFormElements;
    private timeoutID: number;
    private resultsElement: any;

    constructor() {
      this.form = document.querySelector('form[name=search]');
      this.elements = <ISearchFormElements>this.form.elements;
      this.resultsElement = document.getElementById('AddArticle_choices');

      if (fsCommon.isMobile()) {
        this.elements.ismobile.value = '1';
      }

      $(this.elements.text).attr('enterkeyhint', 'search');
    }

    events(): void {
      $('#catalog-search').click(() => {
        headerPopup.show('search');
        $('#search_sb input').focus();
      });

      $('#catalog .input-search').click(() => {
        headerPopup.show('search');
        $('#search_sb input').focus();
      });

      $('#search .js-reset').click(function() {
        var text: string = $(this).html();
        $('#search_sb input[name=text]').val('').focus().trigger('input');
        return false;
      });

      $('#search .examples a').click(function() {
        var text: string = $(this).text();
        $('#search_sb input[name=text]').val(text).focus().trigger('input');
        return false;
      });

      $('#search .search-history a').click(function() {
        var text: string = $(this).html();
        $('#search_sb input[name=text]').val(text).focus().trigger('input');
        return false;
      });

      $('#search .search-history .js-remove').click(function() {
        var id = $(this).data('id');
        //$('#search .search-history .js-history-id' + id).fadeOut();
        $('#search .search-history .js-history-id' + id).hide();

        $.post('/search/history/remove/', {
          id: id
        }, function() {
        }, 'json');
      });

      this.elements.text.addEventListener('focus', (event: Event) => {
        if (!fsCommon.isMobile()) {
          var resultElement: HTMLElement = document.querySelector('#search .result');
          resultElement.classList.add('show');
        }
      });

      $(document).mouseup(function(e: any) {
        var div = $("#search-container");

        if (!div.is(e.target) && div.has(e.target).length === 0) {
          div.find('.result').removeClass('show');
        }
      });

      this.elements.text.addEventListener('blur', (event: Event) => {
        setTimeout(() => {
          if (!fsCommon.isMobile()) {
            var resultElement: HTMLElement = document.querySelector('#search .result');
            //resultElement.style.display = 'none';
          }

          //this.resultsElement.style.display = 'none';
        }, 1000);
      });

      $(this.elements.text).on('input', (event: Event) => {
        var text = this.elements.text.value.trim();

        if ('' === this.elements.text.value) {
          $(this.form).find('.js-reset').css("opacity", "0");
        } else {
          $(this.form).find('.js-reset').css("opacity", "1");
        }

        if (this.timeoutID) {
          clearTimeout(this.timeoutID);
        }

        if (2 < text.length) {
          this.timeoutID = setTimeout(() => {
            this.find(text);
          }, 300);
        } else {
          this.showResult(0);
        }
      });
    }

    private find(text: string) {
      var xhr = new XMLHttpRequest();

      xhr.open('GET', '/search/?search=' + encodeURIComponent(text) + '&ismobile=' + (fsCommon.isMobile() ? '1' : '0'), true);
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.responseType = 'json';
      xhr.onload = () => {
        if (xhr.DONE === xhr.readyState && 200 === xhr.status) {
          var response: ISearchResponse = <ISearchResponse>xhr.response;
          var ul = document.createElement('ul');

          for (var i = 0, length = response.list.length; length > i; i++) {
            var li = document.createElement('li');

            li.innerHTML = response.list[i];
            li.addEventListener('click', function() {
              var el: any = this.querySelector(".name");
              var uri = el.dataset.uri;

              if ('function' === typeof fsDeferEvent) {
                fsDeferEvent('search_autocomplete');
              }

              location.href = uri;
            });

            ul.appendChild(li);
          }

          this.resultsElement.innerHTML = "";
          this.resultsElement.appendChild(ul);

          this.showResult(response.count);
        } else {
          this.showResult(0);
        }
      };

      xhr.send(null);
    }

    private showResult(count: number) {
      if (0 < count) {
        this.resultsElement.style.display = 'block';
        $('#search .examples').hide();
        $('#search .search-history').hide();
      } else {
        this.resultsElement.style.display = 'none';
        $('#search .examples').show();
        $('#search .search-history').show();
      }
    }
  }

  class FlowerShopClass implements IFlowerShopClass {

    isMobile(): boolean {
      if (420 < document.body.clientWidth) {
        return false;
      } else {
        return true;
      }
    }

    showCatalog() {
      headerPopup.hide();

      $('body').addClass('overflow-hidden');
      $('body').addClass('hide-jivo');
      $('#extsearch-mob-container').removeClass('show-layer-container');
      $('#catalog-mob-container').addClass('show-layer-container');
    }

    hideCatalog() {
      $(".layer-container").removeClass("show-layer-container");
      $('body').removeClass('overflow-hidden');
      $('body').removeClass('hide-jivo');

      if ("undefined" !== typeof fsAppJs) {
        fsAppJs.setRefreshEnabled(true);
      }
    }
  }

  fsCommon = new FlowerShopClass();
  headerPopup = new HeaderPopupClass();
  search = new SearchClass();

  search.events();

  $("#showFilters").click(function() {
    $("#catalog-mob-container").removeClass("show-layer-container");
    $("#extsearch-mob-container").toggleClass("show-layer-container");
    $('body').addClass('hide-jivo');

    var state = {
      showFilters: true
    };

    window.history.pushState(state, document.title, location.href);
  });

  $('#catalog-mob-container .accordion-header').click(function() {
    if ($(this).hasClass('collapsed')) {
      $('#catalog-mob-container .accordion-header').addClass('collapsed');
      $("#catalog-mob-container .accordion-content").addClass('collapsed');
    }

    $(this).toggleClass('collapsed');
    $(this).parent().find('.accordion-content').toggleClass('collapsed');
  });
})(document);
